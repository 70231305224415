<template>
  <v-card
    color="app-grey"
    flat
    class="border-10 my-2 product-list-item"
    v-if="!loading"
  >
    <div class="item-row" :style="rowStyles">
      <div class="image" v-if="!hideThumbnailArea">
        <v-card width="100" class="product-img border-10" color="white">
          <v-img
            v-if="!hasNoImg"
            :src="img"
            aspect-ratio="1"
            class="border-10"
          />
          <v-icon v-else style="font-size: 50px">mdi-image</v-icon>
        </v-card>
      </div>
      <div class="name">
        <h3 class="app-black--text">{{ item.name }}</h3>
        <small class="app-gray--text">{{ item.description }}</small>
      </div>
      <div class="quantity text-center">
        <v-chip color="dark-grey app-black--text" class="px-4 font-weight-medium" light>{{ item.quantity }}</v-chip>
      </div>
      <div class="total">
        <span>$</span> {{ totalPrice }}
      </div>
    </div>
  </v-card>
  <v-skeleton-loader
    v-else
    style="height: 138px"
    :key="item.id"
    type="image"
    class="my-2"
  />
</template>

<script>
export default {
  name: 'ProductListItem',
  props: {
    item: {
      type: [Object, Number],
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideThumbnailArea: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    img() {
      return this.item.thumbnail || '/img/no-photo.svg'
    },
    hasNoImg() {
      return !this.item.thumbnail
    },
    totalPrice() {
      return this.item.total_price?.toFixed(2)
    },
    rowStyles() {
      return {
        gridTemplateColumns: this.hideThumbnailArea ? '1fr 100px 100px' : '110px 1fr 100px 100px'
      }
    }
  }
}
</script>

<style  lang="scss">
.item-row {
  display: grid;
  height: 100%;
  min-height: 138px;
  align-items: center;
  gap: 10px;
  padding: 20px;
  .product-img {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .total {
    text-align: right;
  }
}
.flipper {
  .front, .back {
    inset: 0 !important;
    z-index: 0;
  }
}
</style>
